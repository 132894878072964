import Vue from 'vue'
import api from '@/apis/siteMember'
import parser from '@/models/siteMember'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_SITE_MEMBER_REQUEST,
  GET_SITE_MEMBER_SUCCESS,
  GET_SITE_MEMBER_FAILURE,
  GET_SITE_MEMBERS_REQUEST,
  GET_SITE_MEMBERS_SUCCESS,
  GET_SITE_MEMBERS_FAILURE,
  CREATE_SITE_MEMBER_REQUEST,
  CREATE_SITE_MEMBER_SUCCESS,
  CREATE_SITE_MEMBER_FAILURE,
  DELETE_SITE_MEMBER_REQUEST,
  DELETE_SITE_MEMBER_SUCCESS,
  DELETE_SITE_MEMBER_FAILURE,
  UPDATE_SITE_MEMBER_REQUEST,
  UPDATE_SITE_MEMBER_SUCCESS,
  UPDATE_SITE_MEMBER_FAILURE,
  SEND_INVITE_EMAIL_TO_SITE_MEMBER_REQUEST,
  SEND_INVITE_EMAIL_TO_SITE_MEMBER_SUCCESS,
  SEND_INVITE_EMAIL_TO_SITE_MEMBER_FAILURE,
  GET_MEMBER_ROLES_REQUEST,
  GET_MEMBER_ROLES_SUCCESS,
  GET_MEMBER_ROLES_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  siteId: null,
  members: null,
  siteMemberMePermission: null,
  memberRoles: null,
  memberRoleActions: null,
  status: {
    getMember: null,
    getMembers: null,
    createMember: null,
    deleteMember: null,
    updateMember: null,
    sendInviteEmail: null,
    getMemberRoles: null,
  }
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  memberRoles: function (state) {
    return state.memberRoles || []
  },
  memberRoleActions: function (state) {
    return state.memberRoleActions || []
  },
  siteMember: function (state, getters) {
    return function (memberId) {
      return getters.siteMembers[memberId]
    }
  },
  siteMembers: function (state) {
    return state.members || {}
  },
  siteMemberList: function (state) {
    return Object.keys(state.members)
  },
  statusGetSiteMember: function (state) {
    return state.status.getMember
  },
  statusGetSiteMembers: function (state) {
    return state.status.getMembers
  },
  statusCreateSiteMember: function (state) {
    return state.status.createMember
  },
  statusDeleteSiteMember: function (state) {
    return state.status.deleteMember
  },
  statusUpdateSiteMember: function (state) {
    return state.status.updateMember
  },
  statusSendInviteEmailToSiteMember: function (state) {
    return state.status.sendInviteEmail
  },
  statusMemberRoles: function (state) {
    return state.status.getMemberRoles
  },
  siteMemberMePermission: function (state) {
    return state.siteMemberMePermission
  },

}

// actions
const actions = {
  getSiteMember: function ({commit}, {siteId, memberId}) {
    commit(GET_SITE_MEMBER_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteMember({siteId, memberId}).then(
        res => {
          let data = res.body[0]
          commit(GET_SITE_MEMBER_SUCCESS, {data})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_MEMBER_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSiteMembers: function ({commit, getters}, {siteId}) {
    commit(GET_SITE_MEMBERS_REQUEST, {siteId})
    return new Promise((resolve, reject) => {
      api.getSiteMembers({siteId}).then(
        res => {
          let data = res.body
          let myUserId = getters.myUserId
          commit(GET_SITE_MEMBERS_SUCCESS, {data, myUserId})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_MEMBERS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  // [0:OWNER, 1:ADMIN, 2:MEMBER]
  createSiteMember: function ({commit}, {siteId, email, role, firstName, lastName}) {
    commit(CREATE_SITE_MEMBER_REQUEST)
    return new Promise((resolve, reject) => {
      api.createSiteMember({siteId, email, role, firstName, lastName}).then(
        res => {
          commit(CREATE_SITE_MEMBER_SUCCESS)
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(CREATE_SITE_MEMBER_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  deleteSiteMember: function ({commit}, {siteId, memberId}) {
    commit(DELETE_SITE_MEMBER_REQUEST)
    return new Promise((resolve, reject) => {
      api.deleteSiteMember({siteId, memberId}).then(
        res => {
          commit(DELETE_SITE_MEMBER_SUCCESS, {siteId, memberId})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(DELETE_SITE_MEMBER_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  updateSiteMember: function ({commit}, {siteId, memberId, role}) {
    commit(UPDATE_SITE_MEMBER_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateSiteMember({siteId, memberId, role}).then(
        res => {
          commit(UPDATE_SITE_MEMBER_SUCCESS)
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(UPDATE_SITE_MEMBER_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  sendInviteEmailToSiteMember: function ({commit}, {siteId, memberId}) {
    commit(SEND_INVITE_EMAIL_TO_SITE_MEMBER_REQUEST)
    return new Promise((resolve, reject) => {
      api.sendInviteEmailToSiteMember({siteId, memberId}).then(
        res => {
          commit(SEND_INVITE_EMAIL_TO_SITE_MEMBER_SUCCESS)
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(SEND_INVITE_EMAIL_TO_SITE_MEMBER_FAILURE)
          reject(new Error(err.body.message))
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getMemberRoles: function ({commit}) {
    commit(GET_MEMBER_ROLES_REQUEST)
    return new Promise((resolve, reject) => {
      api.getMemberRoles().then(
        res => {
          let data = res.body
          commit(GET_MEMBER_ROLES_SUCCESS, data)
          commit(PROCESS_API_SUCCESS)
          resolve()
        },
        err => {
          commit(GET_MEMBER_ROLES_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_MEMBER_REQUEST]: function (state) {
    state.status.getMember = "requested"
  },
  [GET_SITE_MEMBER_SUCCESS]: function (state, {data}) {
    let memberInfo = parser.parseMemberData(data)

    // add role name from roles matched,
    if (!state.memberRoles) return
    if (!(state.memberRoles instanceof Array)) return
    for (let i = 0; i < state.memberRoles?.length; i++) {
      const action = state.memberRoles[i];
      if (action.id == memberInfo.permissionInfo.role) {
        memberInfo.permissionInfo.roleName = action.name
        break
      }
    }

    if (memberInfo.memberId) {
      state.members = {...state.members, [memberInfo.memberId + '']: memberInfo}
    }
    state.status.getMember = "successful"
  },
  [GET_SITE_MEMBER_FAILURE]: function (state) {
    state.status.getMember = "failed"
  },
  [GET_SITE_MEMBERS_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - site or dateKey changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        ////////////////////////////////////////////
        // member roles is need to keep stored!! 
        // becuase it is global level data.
        ////////////////////////////////////////////
        if (f === 'memberRoles') continue
        else if (f === 'memberRoleActions') continue

        // RESET
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.getMembers = "requested"
  },
  [GET_SITE_MEMBERS_SUCCESS]: function (state, {data, myUserId}) {
    if (!(state.members instanceof Object)) state.members = {}

    let members = {}
    let roles = {}

    data.map(memberData => {
      let memberInfo = parser.parseMemberData(memberData)
      
      // add role name from roles matched,
      if (!state.memberRoles) return
      if (!(state.memberRoles instanceof Array)) return
      for (let i = 0; i < state.memberRoles?.length; i++) {
        const action = state.memberRoles[i];
        if (action.id == memberInfo.permissionInfo.role) {
          memberInfo.permissionInfo.roleName = action.name
          break
        }
      }

      if (memberInfo.memberId) {
        members[memberInfo.memberId + ''] = memberInfo
      }
    })

    let myRoleId = members[myUserId]?.permissionInfo?.role

    for (let i = 0; i < state.memberRoles?.length; i++) {
      const role = state?.memberRoles[i];
      if (role.id == myRoleId) {

        roles.role = myRoleId
        
        for (let ii = 0; ii < role.table?.length; ii++) {
          const action = role.table[ii];
          if (action.resource == 1) roles['arming'] = action.mode
          else if (action.resource == 2) roles['liveView'] = action.mode
          else if (action.resource == 3) roles['eventVideo'] = action.mode
          else if (action.resource == 4) roles['talkdown'] = action.mode
          else if (action.resource == 5) roles['relay'] = action.mode
          else if (action.resource == 6) roles['privacy'] = action.mode
          else if (action.resource == 7) roles['member'] = action.mode
          else if (action.resource == 8) roles['armingSchedule'] = action.mode
          else if (action.resource == 9) roles['incidentReport'] = action.mode
        }
      }
    }

    Vue.set(state, 'siteMemberMePermission', roles)
    Vue.set(state, 'members', members)
    state.status.getMembers = "successful"
  },
  [GET_SITE_MEMBERS_FAILURE]: function (state) {
    state.status.getMembers = "failed"
  },
  [CREATE_SITE_MEMBER_REQUEST]: function (state) {
    state.status.createMember = "requested"
  },
  [CREATE_SITE_MEMBER_SUCCESS]: function (state) {
    state.status.createMember = "successful"
  },
  [CREATE_SITE_MEMBER_FAILURE]: function (state) {
    state.status.createMember = "failed"
  },
  [DELETE_SITE_MEMBER_REQUEST]: function (state) {
    state.status.deleteMember = "requested"
  },
  [DELETE_SITE_MEMBER_SUCCESS]: function (state, {memberId}) {
    if (state.members[memberId]) delete state.members[memberId]
    state.status.deleteMember = "successful"
  },
  [DELETE_SITE_MEMBER_FAILURE]: function (state) {
    state.status.deleteMember = "failed"
  },
  [UPDATE_SITE_MEMBER_REQUEST]: function (state) {
    state.status.updateMember = "requested"
  },
  [UPDATE_SITE_MEMBER_SUCCESS]: function (state) {
    state.status.updateMember = "successful"
  },
  [UPDATE_SITE_MEMBER_FAILURE]: function (state) {
    state.status.updateMember = "failed"
  },
  [SEND_INVITE_EMAIL_TO_SITE_MEMBER_REQUEST]: function (state) {
    state.status.updateMember = "requested"
  },
  [SEND_INVITE_EMAIL_TO_SITE_MEMBER_SUCCESS]: function (state) {
    state.status.updateMember = "successful"
  },
  [SEND_INVITE_EMAIL_TO_SITE_MEMBER_FAILURE]: function (state) {
    state.status.updateMember = "failed"
  },
  [GET_MEMBER_ROLES_REQUEST]: function (state) {
    state.status.getMemberRoles = 'requested'
  },
  [GET_MEMBER_ROLES_SUCCESS]: function (state, data) {
    let rolesInfo = parser.parseMenberRolesData(data)

    state.memberRoleActions = rolesInfo.actions
    state.memberRoles = rolesInfo.roles

    state.status.getMemberRoles = 'successful'
  },
  [GET_MEMBER_ROLES_FAILURE]: function (state) {
    state.status.getMemberRoles = 'failed'
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
